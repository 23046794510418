.myRewardsWrapper {
  padding: 16px 20px;
  /* font-family: "Quicksand"; */
  width: 100%;
  margin-top: var(--pageMarginTop);
}

.myRewardsContainer {
  padding-left: 150px;
  padding-right: 150px;
}

.myRewardsTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 40px;
}

.myRewardsHeading {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
}

.myRewardsBannerContainer {
  width: 100%;
}

.myRewardsBanner {
  display: flex;
}

.myRewardsBannerImage {
  width: 50%;
  /* height: 220px; */
  margin-right: 10px;
  margin-bottom: 10px;
}

.myRewardsPersonImageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  /* margin-left: -55px; */
}

.myRewardsPersonImage {
  width: 470px;
  height: 630px;
}

@media screen and (max-width: 500px) {
  .myRewardsPersonImage {
    width: 370px;
    height: 440px;
  }
  .myRewardsContainer {
    padding-left: 9px;
    padding-right: 9px;
  }
  .myRewardsWrapper {
    padding: 0px;
  }
  .myRewardsBannerImage {
    margin-right: 5px;
    margin-bottom: 5px;
  }
  .myRewardsTitle {
    font-size: 16px;
    margin-top: 3px;
    margin-bottom: 35px;
  }

  .myRewardsHeading {
    font-size: 26px;
    line-height: 34px;
  }
}
@media screen and (max-width: 380px) {
  .myRewardsPersonImage {
    width: 300px;
    height: 400px;
  }
}
