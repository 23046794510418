.table {
  background: white;
  border-radius: 0.2rem;
  width: 100%;
  padding-bottom: 1rem;
  /* color: #212529; */
  margin-bottom: 0;
}

/* .table th:first-child, */
/* .table td:first-child {
  position: sticky;
  left: 0;
  background-color: white;
  color: ;
} */

/* .table td {
  white-space: nowrap;
} */

.table-icon {
  margin-right: 0.4rem;
}

.banner-product-card-sequence {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 30px 0;
  padding: 5px 15px;
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.banner-product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin: 30px 0;
  padding: 20px 15px;
  padding-top: 40px;
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.banner-product-card img {
  width: 400px;
  min-width: 400px;
}
.banner-product-card p {
  cursor: default;
  font-size: 18px;
}
.banner-product-card a {
  text-decoration: none;
}

.banner-product-card .icons {
  position: absolute;
  right: 18px;
  top: 12px;
  display: flex;
  gap: 20px;
}
.equal-children,
.equal-children > * {
  flex-basis: 100%;
  text-align: center;
}

.title {
  word-wrap: break-word !important;
  overflow-wrap: break-word;
}
