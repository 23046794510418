.newAddressWrapper {
  padding: 16px 20px;
  /* font-family: "Quicksand"; */
  width: 100%;
  margin-top: var(--pageMarginTop);
}

.newAddressContainer {
  display: flex;
}

.newAddressContent {
  padding-left: 100px;
  width: 60%;
}

.newAddressContent .newAddressTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 40px;
}

.newAddressContent .newAddressHeading {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
}

/* Image */

.newAddressImageContainer {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  padding-top: 40px;
  padding-right: 100px;
}

.newAddressImageContainer img {
  width: 550px;
  height: 500px;
}

/* Form */

.newAddressFormContainer {
  background-color: #f8f8f8;
  padding: 35px 104px 200px 32px;
  border-radius: 10px;
}

.newAddressStateInput {
  width: 526px;
  height: 65px;
  /* filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25)); */
  padding: 20px 0 20px 28px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
  border: none;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 15px;
  border: 1px solid #d9d9d9;
}

.newAddressStateInput option {
  background-color: #ffffff;
  color: #000000;
}

.newAddressInputDiv {
  padding: 10px;
  width: 530px;
  margin-bottom: 13px;
  border-radius: 7px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}

.newAddressInputField {
  border: none;
  outline: none;
  padding: 5px 5px 7px 10px;
  width: 100%;
  font-size: 20px;
}

.newAddressInputField.textArea {
  height: 140px;
  resize: none;
}
.newAddressInputField.landMark {
  height: 70px;
  resize: none;
}

.newAddressInputField::placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #b2b2b2;
}

.newAddressInputTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 31px;
  color: #000000;
}

.newAddressDoubleInputDiv {
  padding: 10px;
  width: 530px;
  margin-bottom: 13px;
  display: flex;
  gap: 15px;
}

.smallInputDiv {
  width: 15%;
  padding: 0px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 7px;
  background-color: white;
}

.newAddressSmallInputField {
  height: 62px;
  outline: none;
  border: none;
}

.bigInputDiv {
  width: 85%;
  border: 1px solid #d9d9d9;
  border-radius: 7px;
}

.newAddressBigInputField {
  height: 62px;
  cursor: pointer;
  padding: 10px;
  outline: none;
  border: none;
}

.newAddressInputDiv:hover,
.newAddressStateInput:hover,
.newAddressBigInputField:hover {
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
}

.newAddressSmallInputField::placeholder {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 25px;
  color: #000000;
  text-align: center;
}

.newAddresscheckBoxDiv {
  display: flex;
  gap: 5px;
  margin-top: 30px;
  margin-bottom: 17px;
}

.newAddressCheckbox {
  display: inline-block;
  width: 20px;
  border: 2px solid #0066c0;
}

.newAddressConfirmCheckbox {
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 20px;
  color: #0066c0;
}

.newAddresssubmitButton {
  border-radius: 0 0 0 1rem;
  background: #1b325e;
  padding: 12px 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  outline: none;
  cursor: pointer;
  border: none;
  transform: skew(-20deg);
  box-shadow: 0px 4px 4px 0px #00000040;
}

.newAddresssubmitButton p {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  display: inline-block;
  transform: skew(20deg);
  margin: 0;
  color: #ffffff;
}

@media screen and (max-width: 500px) {
  .newAddressContent {
    width: 100%;
    padding-left: 10px;
  }
  .newAddressContainer {
    position: relative;
  }
  .newAddressImageContainer {
    position: absolute;
    top: 0px;
    right: -5px;
    padding-top: 0px;
    padding-right: 0px;
    width: 210px;
    height: 160px;
  }
  .newAddressImageContainer img {
    width: 100%;
    height: 100%;
  }
  .newAddressFormContainer {
    padding: 15px 14px 110px 16px;
  }
  .newAddressStateInput {
    width: 90%;
    height: 45px;
    padding: 10px 0 10px 20px;
  }
  .newAddressInputDiv {
    padding: 6px;
    width: 90%;
  }
  .newAddressDoubleInputDiv {
    width: 90%;
    gap: 19px;
    padding: 0;
  }
  .bigInputDiv {
    width: 73%;
    border: 1px solid #d9d9d9;
    border-radius: 7px;
  }

  .newAddressBigInputField {
    height: 62px;
    cursor: pointer;
    padding: 10px;
    outline: none;
    border: none;
  }

  .smallInputDiv {
    width: 27%;
    padding: 0px 7px;
    border: 1px solid #d9d9d9;
    border-radius: 7px;
    background-color: white;
  }

  .newAddressSmallInputField::placeholder {
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    color: #000000;
    text-align: center;
  }

  .newAddressInputTitle {
    font-size: 17px;
    line-height: 25px;
  }

  .newAddressContent .newAddressTitle {
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 20px;
  }

  .newAddressContent .newAddressHeading {
    width: 132px;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 44px;
  }
}

@media screen and (max-width: 380px) {
  .newAddressImageContainer {
    width: 170px;
    height: 130px;
    top: 25px;
  }
}
