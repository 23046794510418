/* .App {
  display: flex;
  width: 100%;
  align-items: stretch;
} */

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
@import "https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,500,600,700";

.DashboardWrapper {
  display: flex !important;
  width: 100%;
  align-items: stretch;
}

.dashboard_Whole_Wrapper {
  /*font-family: 'Poppins', sans-serif;*/
  /* font-family: "Nunito Sans", sans-serif; */
  /*background: #fafafa;*/
  background-color: #f5f9fc;
  min-width: 100%;
}

p.ml-3 {
  margin-left: 1rem !important;
}

/* a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
} */

/* a tag */
.dash_sidebar_a,
.dash_sidebar_a:hover {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}

.dash_sidebar_a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}

/* ---------------------------------------------------
      SIDEBAR STYLE
  ----------------------------------------------------- */
.sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #354052;
  color: #adb5bd;
  margin-left: -250px;
  transition: all 0.5s;
  position: fixed !important;

  left: 0;
  height: -webkit-fill-available;
  z-index: 1;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
  position: relative;
}

.sidebar-header {
  background: #1b325e;
  color: #adb5bd;
}

.sidebar-header h3 {
  color: #fff;
  padding: 1em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.collapse.show,
.collapsing {
  background: #313b4c;
}

.nav-item {
  /*color: #ced4da;*/
  color: #adb5bd;
  font-weight: 300;
}

.nav-item.open {
  color: #e9ecef;
  background: #313b4c;
}

.nav-item.active {
  color: #e9ecef;
  background: #2d3646 !important;
}

.nav-item:hover {
  background: #313b4c;
  color: #fff;
  transition: 0.2s;
}

.nav-item .svg-inline--fa {
  color: inherit;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%;
}

.sidebar-header > button {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

.adminNavLink:hover {
  background: rgba(255, 255, 255, 0.17);
}
.adminNavLink {
  column-gap: 1rem;
  align-items: center;
  padding: 0.5rem !important;
}

.bgActive {
  background: rgba(255, 255, 255, 0.17);
}
.NavOpen {
  padding-left: 19rem !important;
}

.NavClose {
  padding-left: 5rem !important;
}
.mainNavDiv {
  padding-top: 2rem !important;
}
/* ---------------------------------------------------
      CONTENT STYLE
  ----------------------------------------------------- */
.content {
  padding: 20px;
  min-height: 100vh;
}

.content.is-open {
  margin-left: 1px !important;
}

@media only screen and (max-width: 575.98px) {
  .DashboardWrapper {
    overflow: hidden;
  }

  .content.is-open {
    /*margin-left: 100%;*/
    display: none;
    transition: all 0.5s;
  }

  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
    height: 100vh !important;
    position: relative !important;
  }

  .sidebar.is-open > .sidebar-header button {
    display: block;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
}

.container a {
  text-decoration: none;
}

.dropdown-btn {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.dropdown-btn p {
  margin-bottom: 0;
}

.content:where(.is-open) > .container {
  width: 950px;
}
.order-dialog-box .css-2zwj5t-MuiPaper-root-MuiDialog-paper.MuiDialog-paperScrollBody {
  min-width: 80vw !important;
}

.addPointBtn {
  background-color: black;
  color: white;
  border-radius: 7px;
  margin: 10px 0px;
  padding: 4px 7px;
}

.duration-300 {
  transition-duration: 300ms;
}

.text-white {
  color: #ffffff;
}

.border-dark-purple {
  border-color: #422d61;
}

.border-2 {
  border-width: 2px;
}

.rounded-full {
  border-radius: 9999px;
}

.cursor-pointer {
  cursor: pointer;
}

.border {
  border-width: 1px;
  border-style: solid;
}

.rotate-180 {
  transform: rotate(180deg);
}

.items-center {
  align-items: center;
}

.rotate-360 {
  transform: rotate(360deg);
}

.origin-left {
  transform-origin: left;
}

.font-medium {
  font-weight: 500;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.scale-0 {
  transform: scale(0);
}

.origin-left {
  transform-origin: left;
}

.hidden {
  display: none !important;
}

.adminNavLink {
  display: flex;
  padding: 0.5rem;
  cursor: pointer;
}

.hover\:bg-light-white:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.gap-x-4 > * + * {
  margin-left: 1rem;
}

.no-underline {
  text-decoration: none;
}

.bgActive {
  background-color: rgba(255, 255, 255, 0.1);
}
