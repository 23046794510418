.dropDownContainer {
  position: fixed;
  left: 50px;
  bottom: 20;
  width: 170px;
  z-index: 9999;
  opacity: 0;
  animation: fadeAnimate 0.3s forwards;
}

.dropDownContainer[data-categorydropdownopen="open"] {
  backdrop-filter: blur(3px);
}

@keyframes fadeAnimate {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dropDownItemsCon {
  background: linear-gradient(177.95deg, #f3f6ff 1.73%, rgba(243, 246, 255, 0) 115.91%);
  border-radius: 5px;
  position: relative;
}

.dropDownItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0.6rem 0 1rem;
  position: relative;
}
.dropDownItem > h4 {
  color: black;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  flex-grow: 1;
}

.rightArrowIcon {
  transform: translateY(-4px);
}

.rightArrowIcon .MuiSvgIcon-root {
  font-size: 19px;
  cursor: pointer;
  color: black;
}

.MuiSvgIcon-root.rotate {
  transform: rotate(90deg);
}

@media screen and (max-width: 500px) {
  .dropDownItem > h4 {
    font-size: 0.75rem;
  }
  .dropDownItem {
    padding: 5px 8px;
  }
  .dropDownContainer {
    width: 35vw;
    left: 7px;
    top: 148px;
  }
}
