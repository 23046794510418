/* Banner  */

.retailBanner-component {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
retailBanner {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.retailBanner img {
  width: 100%;
  max-height: 170px;
  min-height: 150px;
  object-fit: cover;
  margin-bottom: 10px;
}
.mobilescreen {
  display: flex;
  margin-top: 10px;
}
.desktop {
  display: none;
}
@media screen and (min-width: 708px) {
  .retailBanner-component {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 10px;
  }
  .retailBanner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .retailBanner img {
    width: 49.5%;
    max-height: 450px;
    min-height: 400px;
    object-fit: cover;
  }
  .mobilescreen {
    display: none;
  }
  .desktop {
    display: flex;
    margin-top: 8px;
  }
}

/* GetInTouch  */

.gtpContainer {
  display: flex;
  width: 100%;
}

.gtpContent,
.gtpImage {
  width: 50%;
}

.gtpImage {
  background-position: center;
  background-size: cover;
  border-radius: none;
  transform: translateX(5px);
}

.gtpContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 80px;
}

.gtpSubContent {
  display: flex;
  align-items: center;
  gap: 20px;
}

.gtpSubContentImage {
  width: 60px;
  height: 60px;
  background-position: center;
  background-size: cover;
}

.gtpSubContentText {
  display: flex;
  flex-direction: column;
  width: 380px;
}

.gtpSubContentTextH1 {
  font-size: 22px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 3px;
}

/* Google Maps  */

.googleMapsContainer {
  width: 80%;
  padding: 40px;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
}

.googleMapsContainerHeader {
  display: flex;
  gap: 10px;
  margin-bottom: 50px;
}

.googleMapsDirectionHeading {
  font-weight: 700;
  font-size: 40px;
  line-height: 80px;
  color: #1b325e;
}

.northIcon {
  width: 65px;
  height: 65px;
  background-position: center;
  background-size: cover;
}

@media screen and (max-width: 500px) {
  .gtpContainer {
    flex-direction: column;
  }

  .gtpContent,
  .gtpImage {
    width: 100%;
  }

  .gtpImage {
    transform: translateX(0px);
  }

  .gtpContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 3px;
  }

  .gtpSubContent {
    display: flex;
    gap: 20px;
  }

  .gtpSubContentImage {
    width: 60px;
    height: 60px;
    background-position: center;
    background-size: cover;
  }

  .gtpSubContentText {
    display: flex;
    flex-direction: column;
    width: 200px;
  }

  .gtpSubContentTextAddress {
    width: 260px;
  }

  .gtpSubContentTextH1 {
    font-size: 22px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 3px;
  }

  .googleMapsContainer {
    width: 90%;
    padding: 10px;
  }

  .googleMapsContainerHeader {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .googleMapsDirectionHeading {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    color: #1b325e;
  }

  .northIcon {
    width: 35px;
    height: 35px;
  }
}
