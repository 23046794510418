.wishlist_page_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.newWishListContainer {
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  /* font-family: "Quicksand"; */
}

.newWishListDetails {
  padding: 10px;
  margin-bottom: 19px;
}

.newWishListTitle {
  margin-top: 5px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.newWishListTitle h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
}

.newWishListUserContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.newWishListHeading,
.newWishListUserName {
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 40px;
  color: #000000;
}

.newWishListHeading {
  margin-bottom: 34px;
}

.newWishListImage {
  display: flex;
  align-items: center;
  gap: 18px;
}

.newWishListImage img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

/* Seactin Ends */

.newWishListCart {
  display: flex;
  width: 100%;
  background-color: #f3f2f3;
  padding: 34px 30px 20px 110px;
  margin-top: 10px;
  margin-bottom: 30px;
  border-radius: 10px;
  gap: 20px;
}

.newWishListDetailed {
  width: 55%;
  display: flex;
}

.newWishListDetailed:where(:focus-visible, :hover) {
  font-weight: bolder !important;
  cursor: pointer;
}

.newWishListDetailed2 {
  padding: 10px 0px 0px 15px;
  margin-right: 40px;
  display: flex;
  justify-content: space-between;
}
.newWishListDetailed2 div .newWishListname {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  color: #000000;
}

.newWishListDetailed2 .newWishListDetailed2Div p {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #707070;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.newWishListDetailed3 {
  padding-top: 10px;
}

.newWishListDetailed3 h3 {
  text-align: right;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
  text-decoration-line: line-through;
  color: #7f7f7f;
}

/* Buttons */

.newWishListButtonSection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 35%;
  padding: 16px 30px;
}

.newWishListAddButton {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.newWishListPrice {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 47px;
  color: #241a6b;
}

.newWishListAddToCartButton {
  border-radius: 0 0 0 1rem;
  background: #1b325e;
  padding: 15px 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 340px;
  outline: none;
  cursor: pointer;
  border: none;
  transform: skew(-20deg);
  box-shadow: 0px 4px 4px 0px #00000040;
}

.newWishListAddToCartButton p {
  font-size: 21px;
  line-height: 16px;
  font-weight: 550;
  letter-spacing: -0.5px;
  display: inline-block;
  transform: skew(20deg);
  margin: 0;
  color: #ffffff;
}

.newWishListAddedDate {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 9px;
  color: #7f7f7f;
}

.newWishListdeleteButton {
  outline: none;
  border: none;
  display: flex;
  align-items: center;
}

.newWishListdeleteButton:has(:hover, :focus-visible) svg,
.newWishListdeleteButton:has(:hover, :focus-visible) span {
  fill: red !important;
  color: red !important;
  transition: fill 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.newWishListdeleteButton span {
  font-style: normal;
  font-weight: 550;
  font-size: 18px;
  line-height: 25px;
  color: #707070;
}

.newWishListDetailed1 {
  max-width: 161px;
  max-height: 161px;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 500px) {
  .newWishListdeleteButton span {
    font-size: 14px;
    line-height: 20px;
  }

  .newWishListContainer {
    padding: 0;
  }
  .newWishListDetails {
    padding: 5px 20px;
  }
  .newWishListTitle {
    margin-bottom: 10px;
  }
  .newWishListTitle h1 {
    font-size: 14px;
  }
  .newWishListHeading,
  .newWishListUserName {
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #000000;
  }
  .newWishListUserName {
    font-size: 22px;
  }
  .newWishListStart {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .newWishListHeading {
    margin-bottom: 10px;
  }
  .newWishListImage {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .newWishListImage img {
    width: 66px;
    height: 66px;
    border-radius: 50%;
  }

  /* Section Ends */

  .newWishListAddToCartButton {
    padding: 12px 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    outline: none;
    cursor: pointer;
    width: 160px;
    border: none;
    transform: skew(-20deg);
    box-shadow: 0px 4px 4px 0px #00000040;
  }

  .newWishListAddToCartButton p {
    font-size: 14px;
    line-height: 16px;
    font-weight: 550;
    letter-spacing: -0.5px;
    display: inline-block;
    transform: skew(20deg);
    margin: 0;
    color: #ffffff;
  }

  .newWishListCart {
    padding: 16px 0px 25px 10px;
    margin-bottom: 20px;
    flex-direction: column;
    border-radius: 0;
    gap: 0;
  }
  .newWishListDetailed {
    width: 100%;
  }
  .newWishListDetailed2 {
    flex-direction: column;
    padding: 10px 0px 0px 13px;
  }
  .newWishListDetailed2 div .newWishListname {
    font-size: 11px;
    line-height: 13px;
    color: #000000;
  }

  .newWishListDetailed1 img {
    width: 100px;
    height: 100px;
  }
  .newWishListDetailed3 {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    text-align: left;
    justify-content: space-between;
    padding-top: 0;
    padding-right: 40px;
    gap: 10px;
  }
  .newWishListDetailed2 .newWishListDetailed2Div p {
    font-size: 13px;
    line-height: 14px;
    margin-bottom: 0px;
  }
  .newWishListDetailed3 h3,
  .newWishListPrice {
    display: inline-block;
  }
  .newWishListPrice {
    font-size: 1.45rem;
    line-height: 25px;
    color: #241a6b;
  }

  .newWishListDetailed3 h3 {
    font-size: 1rem;
    line-height: 20px;
  }

  .newWishListDetailed2 {
    margin-right: 0px;
  }

  /* Buttons */

  .newWishListButtonSection {
    width: 100%;
    flex-direction: column;
    padding: 2px 0px;
    text-align: left;
    margin-left: -26px;
  }
  .newWishListAddedDate {
    font-size: 14px;
    line-height: 19px;
  }
}

@media screen and (max-width: 991px) {
  .newWishListCart {
    padding-left: 16px;
    flex-direction: column;
  }

  .newWishListDetailed {
    width: 100% !important;
  }

  .newWishListButtonSection {
    width: 100% !important;
  }

  .newWishListDetailed2 {
    margin-right: 0;
  }

  .newWishListPrice {
    font-size: 26px !important;
  }
}

@media screen and (min-width: 501px) and (max-width: 1600px) {
  /* styles for screens between 500px and 1600px wide */
  .newWishListDetailed {
    width: 65%;
  }
  .newWishListButtonSection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 45%;
    padding: 16px 30px;
  }
  .newWishListAddToCartButton {
    padding: 12px 39px;
    width: 240px;
  }

  .newWishListAddToCartButton p {
    font-size: 16px;
    line-height: 16px;
    font-weight: 550;
    letter-spacing: -0.5px;
    display: inline-block;
    transform: skew(20deg);
    margin: 0;
    color: #ffffff;
  }
  .newWishListdeleteButton span {
    font-style: normal;
    font-weight: 550;
    font-size: 14px;
    line-height: 25px;
    color: #707070;
  }
}
