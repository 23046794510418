.your_Order_Container {
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  /* padding-top: 16px; */
  /* font-family: "Quicksand"; */
  margin-top: var(--pageMarginTop);
}

:root {
  --downloadBtnMarginTop: -3.5rem;
}

.your_order_details {
  padding: 10px;
}

.your_order_placed {
  margin-bottom: 45px;
  display: flex;
  justify-content: space-between;
}

.your_order_placed h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}

.your_order_div {
  display: flex;
}

.your_order_tracks {
  width: 70%;
}
.combo-product-text {
  border-radius: 5px;
}

.your_order_tracks_inside {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 40px;
}

.your_order_header h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 37px;
  color: #000000;
}

.your_order_header p {
  font-size: 15px;
}

.your_order_id p {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  color: #707070;
  margin-bottom: -38px;
}

.your_order_search {
  display: flex;
  border: 1px solid #bebebe;
  padding: 5px 10px;
  border-radius: 6px;
}

.your_order_searchbar {
  outline: none;
  border: none;
  width: 300px;
}

.your_ordersearch_icon {
  margin-right: 10px;
  font-weight: 300;
  font-size: 1rem;
}

.your_order_searchbar::placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  color: #7f7f7f;
}

.your_order_image {
  width: 30%;
}

.your_order_total_price {
  z-index: 1;
}

.your_order-_image img {
  width: 260.86px;
  height: 200px;
}

.your_order_tracks_second {
  display: flex;
  gap: 237px;
}

.your_order_title,
.your_order_placed_date {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #3581c1;
}

.your_order_placed_date {
  font-size: 19px;
}

.your_order_total_price h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #241a6b;
}

/* Section-1 Ends  */

.your_order_cart {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  background-color: #f3f2f3;
  padding: 34px;
  margin-top: 10px;
  margin-bottom: 30px;
  border-radius: 10px;
}

.your_order_cart p {
  font-weight: 600;
  margin-bottom: 0;
}

.your_order_cart > div {
  display: flex;
}

.your_order_cart_detailed {
  display: flex;
}

.your_order_cart_detailed_2 {
  padding: 0px 0px 0px 1rem;
  margin-right: 40px;
  width: 43%;
  text-decoration: none;
}

.your_order_cart_detailed_2:hover,
.your_order_cart_detailed_2:focus-visible {
  text-decoration: underline;
  text-decoration-color: black;
  cursor: pointer;
}

.your_order_cart_detailed_2 h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  color: #000000;
}

.your_order_cart_detailed_2 .your_order_cart_detailed_2_div h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #707070;
}

.your_order_cart_detailed_3 {
  padding-top: 10px;
}

.your_order_cart_detailed_3 h3 {
  text-align: right;

  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
  text-decoration-line: line-through;
  color: #7f7f7f;
}

.your_order_cart_detailed_3 h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 47px;
  color: #241a6b;
}

.your_order_cart_buttons {
  width: 45%;
  display: flex;
}

.package-canceled {
  font-size: 1.2rem;
  flex-grow: 1;
  text-align: center;
}

.your_order_cart_buttons_1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  gap: 10px;
}

.your_order_submit_button {
  border-radius: 0 0 0 1rem;
  background: #1b325e;
  padding: 14px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  outline: none;
  cursor: pointer;
  margin-top: 10px;
  border: none;
  transform: skew(-20deg);
  box-shadow: 0px 4px 4px 0px #00000040;
}

.your_order_submit_button p {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  display: inline-block;
  transform: skew(20deg);
  margin: 0;
  color: #ffffff;
}

.your_order_cancel_button {
  outline: none;
  border: none;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.your_order_cart_buttons_1_cancel {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #707070;
}

.your_order_cart_buttons_2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
}

.your_cart_downloadicon_container {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.your_cart_downloadicon {
  font-size: 45px !important;
  font-weight: 700 !important;
}

.cart_texts {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  color: #707070;
}

.card-display-row::-webkit-scrollbar {
  display: none;
}

.your_order_cart_detailed_1 {
  aspect-ratio: 3/4;
}

.your_order_cart_detailed_1 img {
  width: 100%;
  border-radius: 5px;
}

.order_total_price {
  display: flex;
  justify-content: flex-end;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  color: #241a6b;
  margin-right: 2rem;
}

.review_btn {
  color: #3581c1;
  font-weight: 700;
  font-weight: 700;
}

.review_btn:where(:hover, :focus-visible) {
  cursor: pointer;
  text-decoration: underline;
  color: #1b325e;
}

.original-price {
  font-size: 0.9rem;
  text-align: end;
  margin-bottom: -13px !important;
}

.order-cancel-tick {
  max-width: 205px;
  max-height: 205px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.order-cancel-tick img {
  /* width: 30%; */
  margin-top: 17px;
  margin-bottom: 1rem;
}

@media screen and (max-width: 500px) {
  .your_Order_Container {
    padding: 16px 0 0px;
  }
  .onsitego-heading,
  .onsitego-text {
    font-size: 14px;
  }

  .your_order_details {
    padding: 5px 10px;
  }

  .your_order_placed h1 {
    font-size: 16px;
  }

  .your_order_header h1 {
    font-weight: 700;
    font-size: 17px;
    line-height: 14px;
    margin-bottom: 2px;
    margin-top: -2px;
    color: #000000;
  }

  .your_order_header p {
    font-size: 10px;
  }

  .your_order_search {
    padding: 1px 6px;
  }

  .delivered-text {
    flex-grow: 1;
    text-align: center;
  }

  .invoice-not-generated-text {
    font-size: 13px;
  }

  .combo-product-text {
    font-size: 10px !important;
    line-height: 16px !important;
    font-weight: 500 !important;
    padding: 6px !important;
  }
  .your_order_searchbar {
    width: 180px;
  }

  .your_order-id p {
    font-size: 10px;
    line-height: 28px;
  }

  .your_order_tracks {
    width: 100%;
  }

  .your_ordersearch_icon {
    margin-right: 5px;
    font-weight: 300;
    font-size: 0.4rem;
  }

  .your_order_tracks_inside {
    align-items: flex-start;
    gap: 20px;
  }

  .your_order_searchbar::placeholder {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #7f7f7f;
  }

  .your_order_image,
  .your_order_total_price {
    width: 33%;
  }

  .your_order_date {
    width: 34%;
  }

  .your_order_image img {
    width: 110px;
    height: 80px;
  }

  .your_order_tracks_second {
    gap: 0px;
  }

  .your_order_total_price {
    padding-left: 20px;
  }

  .your_order_total_price h4 {
    font-size: 14px;
    line-height: 18px;
  }

  /* Section Ends  */

  .your_order_cart {
    padding: 10px 10px 10px 15px;
    margin-bottom: 20px;
    flex-direction: column;
  }

  /* .your_order_cart_detailed_1 {
    width: 150px;
  } */

  .your_order_cart_detailed_1 img {
    width: 90px;
    height: 90px;
  }

  .your_order_cart_detailed {
    width: 100%;
    display: flex;
  }

  .your_order_cart_detailed_2 {
    padding: 0px;
    margin: 0 14px;
  }

  .your_order_cart_buttons {
    width: 100%;
    display: flex;
    margin-top: 5px;
  }

  .your_order_cart_detailed_2 .your_order_name {
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }

  .your_order_cart_detailed_2 .your_order_cart_detailed_2_div h1 {
    font-size: 12px;
    line-height: 13px;
  }

  .your_order_cart_buttons_1 {
    flex-direction: row;
  }

  .your_order_submit_button {
    padding: 8px 15px;
    display: flex;
    margin-top: 0;
    justify-content: center;
    align-items: center;
    color: white;
    outline: none;
    cursor: pointer;
    border: none;
    transform: skew(-20deg);
    box-shadow: 0px 4px 4px 0px #00000040;
  }

  .your_order_submit_button p {
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: -0.5px;
    display: inline-block;
    transform: skew(20deg);
    margin: 0;
    color: #ffffff;
  }

  .your_order_cancel_button {
    outline: none;
    border: none;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .your_order_cart_buttons_1_cancel {
    font-size: 10px;
    line-height: 20px;
  }

  .your_order_cart_buttons_2 {
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .your_cart_downloadicon {
    font-size: 24px !important;
  }

  .your_order_cart_buttons_2_text {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    padding: 0 12px 0px 0px;
  }

  .cart_texts {
    font-size: 10px;
    line-height: 3px;
  }

  .your_order_cart_detailed_2_container {
    display: flex;
    justify-content: space-between;
  }

  .your_order_cart_detailed_3 {
    padding-top: 0px;
  }

  .your_order_cart_detailed_3 h3 {
    font-size: 17px;
    line-height: 17px;
  }

  .your_order_cart_detailed_3 h1 {
    font-size: 15px;
  }

  .your_order_cart_detailed_2 {
    width: 100%;
  }

  .your_order_title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }

  .your_order_placed_date {
    font-size: 15px;
  }

  .your_order_cart > div {
    flex-direction: column;
    margin-right: 0;
  }

  .order-cancel-tick img {
    width: 50%;
    margin-bottom: 1rem;
  }

  .orderCancelledImage {
    width: 80%;
  }
}

@media screen and (min-width: 501px) and (max-width: 1600px) {
  /* styles for screens between 500px and 1600px wide */
  .your_order_submit_button p {
    font-size: 17px;
  }

  .your_order_tracks_second {
    display: flex;
    gap: 57px;
  }

  .order_total_price {
    /** margin-top: var(--downloadBtnMarginTop); */
  }

  .your_order_cart_buttons {
    align-items: center;
    /* margin-top: calc(2 * var(--downloadBtnMarginTop)); */
  }
}
