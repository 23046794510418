@media (max-width: 768px) {
  .table {
    font-size: smaller;
  }
  .table th,
  .table td {
    padding: 0.25rem;
  }
  .table th:nth-child(3),
  .table td:nth-child(3) {
    white-space: normal; /* Allows the description column to wrap text */
  }
}
.invoice-container {
  font-size: 12px;
  line-height: 15px;
}
