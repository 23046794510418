.Main {
  background: radial-conic-gradient(#e16f91 0 15deg, #e0517f 15deg, 30deg);
  height: max-content;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  min-height: 100vh;
}

.final_bg {
  background: url(../../assets/png/backImg.jpg);
  height: max-content;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  min-height: 750px;
  background-position: center;
}

.offers {
  width: 350px;
  top: 24%;
  left: 14%;
}

.big-gift-image {
  max-width: 350px;
  width: 100%;
}

.Main2 {
  height: max-content;
  background-image: url(../../assets/smartBandhan/SmartBandhanBG.png);
  background-size: cover;
  background-position: center center;
  color: white;
  min-height: 100vh;
}

.main__dom {
  width: 100%;
  min-width: 320px;
}

.main__container {
  margin: auto 0px;
  margin-bottom: -32px;
  position: relative;
  z-index: 1;
}

.sub-container {
  /* max-width: 600px; */
  margin: auto;
}

.fourthAnniversary-img {
  max-width: 400px;
  width: 100%;
}

.btn.btn-warning {
  background-color: #1b325e;
  color: white;
}

.mainlogo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.border-control {
  margin-left: 0;
  max-height: 100%;
}

.border-control2 {
  float: right;
  max-height: 100%;
  transform: rotate(180deg);
}

.logo-control {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0%;
}

.flower-bottom {
  position: absolute;
  bottom: 0;
  width: 93vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 320px) {
  .main__dom {
    width: 320px !important;
  }
}

@media (max-width: 480px) {
  .offers {
    left: 2%;
  }
}

@media (max-width: 380px) {
  .final_bg {
    background-size: contain;
    color: white;
  }

  .offers {
    width: 150px;
    top: 40%;
    left: 22%;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .main__dom {
    width: 320px !important;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
}

.tet {
  margin: 0px;
  padding: 0px;
  width: 100%;
  outline: none;
  height: 30px;
  border-radius: 5px;
}

.butuun {
  position: absolute;
  top: 0;
  border-radius: 5px;
  right: 0px;
  z-index: 2;
  border: none;
  top: 2px;
  height: 30px;
  cursor: pointer;
  color: white;
  background-color: #1e90ff;
  transform: translateX(2px);
}

.ig {
  /* adjust the below to control the 3D effect */
  --x: 10px;
  --y: 20px;
  transform: perspective(1000px) rotateX(var(--_a, 40deg));
  /* */
  clip-path: polygon(
    var(--y) var(--y),
    calc(100% - var(--y)) var(--y),
    calc(100% - var(--y)) calc(100% - var(--y)),
    calc(100% - var(--y) - var(--x)) var(--_c, 100%),
    calc(var(--x) + var(--y)) var(--_c, 100%),
    var(--y) calc(100% - var(--y))
  );
  outline: var(--y) solid rgba(0, 0, 0, 0.4);
  outline-offset: calc(-1 * var(--y));
  padding: var(--y) var(--y) 0 var(--y);
  transition: 1s;
}

.rt {
  transform: rotate(270deg);
  position: fixed;
  bottom: 90px !important;
  left: 10px !important;
}

.bts {
  -moz-animation: scale 1s;
  /* Firefox */
  -webkit-animation: scale 1s;
  /* Safari and Chrome */
  -o-animation: scale 1s;
  /* Opera */
  animation: scale 1s;
}

@keyframes scale {
  from {
    width: 0px;
    height: 0px;
  }

  to {
    width: 200px;
    height: 200px;
  }
}

@-moz-keyframes scale {
  /* Firefox */
  from {
    width: 200px;
    height: 200px;
  }

  to {
    width: 0px;
    height: 0px;
  }
}

@-webkit-keyframes scale {
  /* Safari and Chrome */
  from {
    width: 200px;
    height: 200px;
  }

  to {
    width: 0px;
    height: 0px;
  }
}

@-o-keyframes scale {
  /* Opera */
  from {
    width: 200px;
    height: 200px;
  }

  to {
    width: 0px;
    height: 0px;
  }
}

/* .outer-2{
  position: relative;
  left: 80%;
  top: 20%;
  margin-top: 0rem;
} */

.outer-2 > img {
  position: absolute;
  z-index: 1;
  left: 20px;
  top: 80px;
}

.rating-icon {
  transition: 350ms ease-in;
}

.logo-img {
  margin: 20px 0;
  width: 400px;
}

.offer-date {
  background: linear-gradient(90deg, #dd9f47 1.34%, #f6ebaf 52.31%, #dd9f47 101.29%);
  max-width: max-content;
  margin: auto;
  color: #102250;
  font-size: 18px;
  border-radius: 4px;
  padding: 4px 8px;
}

.checkout-offer-text {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 1.15rem;
  color: #ffffff;
}

.outer-3 > img {
  position: absolute;
  z-index: 1;
  right: 80px;
  top: 200px;
}

.outer-2 > .pt {
  position: relative;
  z-index: 2;
}

.outer-1 {
  position: relative;
  left: 20%;
  margin-top: 0rem;
  /* width: 100px;
  height: 100px; */
}

.outer-1 > img {
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 0px;
}

.outer-1 > .pt {
  position: relative;
  z-index: 2;
}

.footer__main {
  margin-top: 400px;
}

.ring__main {
  /* margin-top: -106px !important; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 4px solid red; */
  width: 320px;
  flex-direction: column;
}

.ring__main__float {
  /* margin-top: -506px !important; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  flex-direction: column;
  display: flex !important;
  justify-content: center !important;
  /* border: 4px solid blue; */
  touch-action: none;
  pointer-events: none;
}

.ring__main__float > h6 {
  display: none !important;
}

.curtains {
  -moz-animation: curtain-animation infinite ease-in 4s alternate-reverse;
  /* Firefox */
  -webkit-animation: curtain-animation infinite ease-in 4s alternate-reverse;
  /* Safari and Chrome */
  -o-animation: curtain-animation infinite ease-in 4s alternate-reverse;
  /* Opera */
  animation: curtain-animation infinite ease-in 4s alternate-reverse;
}

#stage-container {
  width: 70%;
  margin: 0 auto;
}

@keyframes curtain-animation {
  0% {
    width: 33px;
  }

  33% {
    width: 37px;
  }

  66% {
    width: 40px;
  }

  100% {
    width: 43px;
  }
}

@-moz-keyframes curtain-animation {
  0% {
    width: 33px;
  }

  33% {
    width: 37px;
  }

  66% {
    width: 40px;
  }

  100% {
    width: 43px;
  }
}

@-webkit-keyframes curtain-animation {
  0% {
    width: 33px;
  }

  33% {
    width: 37px;
  }

  66% {
    width: 40px;
  }

  100% {
    width: 43px;
  }
}

@-o-keyframes curtain-animation {
  0% {
    width: 33px;
  }

  33% {
    width: 37px;
  }

  66% {
    width: 40px;
  }

  100% {
    width: 43px;
  }
}

@keyframes brightness-animation {
  0% {
    filter: brightness(0.7);
  }

  33% {
    filter: brightness(0.9);
  }

  66% {
    filter: brightness(1.4);
  }

  100% {
    filter: brightness(1.9);
  }
}

@-moz-keyframes brightness-animation {
  0% {
    filter: brightness(0.7);
  }

  33% {
    filter: brightness(0.9);
  }

  66% {
    filter: brightness(1.4);
  }

  100% {
    filter: brightness(1.9);
  }
}

@-webkit-keyframes brightness-animation {
  0% {
    filter: brightness(0.7);
  }

  33% {
    filter: brightness(0.9);
  }

  66% {
    filter: brightness(1.4);
  }

  100% {
    filter: brightness(1.9);
  }
}

@-o-keyframes brightness-animation {
  0% {
    filter: brightness(0.7);
  }

  33% {
    filter: brightness(0.9);
  }

  66% {
    filter: brightness(1.4);
  }

  100% {
    filter: brightness(1.9);
  }
}

.main-prize {
  display: grid;
  place-content: center;
}

.btn-box-shadow {
  box-shadow: 0px 0px 22px 5px #e7a500a6;
}

#fifth-page-heading {
  display: grid;
  place-content: center;
  font-size: 1.3rem;
  padding: 20px 0;
  font-weight: bold;
  text-transform: capitalize;
  width: 60%;
  text-align: center;
  margin: auto;
}

.fifth-image-animation-start {
  transform: scale(1.3);
  filter: brightness(1.3);
  transition: all 200ms ease-in;
}

.fifth-image-animation-end {
  transform: scale(1);
  filter: brightness(0.9);
  transition: all 400ms ease-in;
}

@keyframes brightness-animation-game {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-5px);
  }

  60% {
    transform: translateY(-2px);
  }
}

@-moz-keyframes brightness-animation-game {
  /* Firefox */
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-5px);
  }

  60% {
    transform: translateY(-2px);
  }
}

@-webkit-keyframes brightness-animation-game {
  /* Safari and Chrome */
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-5px);
  }

  60% {
    transform: translateY(-2px);
  }
}

@-o-keyframes brightness-animation-game {
  /* Opera */
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-5px);
  }

  60% {
    transform: translateY(-2px);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@-moz-keyframes rotation {
  /* Firefox */
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@-webkit-keyframes rotation {
  /* Safari and Chrome */
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@-o-keyframes rotation {
  /* Opera */
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.start-to-play-text {
  color: yellow;
  font-weight: bold;
  letter-spacing: 2px;
  -moz-animation: brightness-animation infinite ease-in 1s alternate-reverse;
  /* Firefox */
  -webkit-animation: brightness-animation infinite ease-in 1s alternate-reverse;
  /* Safari and Chrome */
  -o-animation: brightness-animation infinite ease-in 1s alternate-reverse;
  /* Opera */
  animation: brightness-animation infinite ease-in 1s alternate-reverse;
  margin-bottom: -15px;
  font-size: 1.5rem;
}

input[type="date"]::before {
  color: #495057;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}

@keyframes background-change {
  0% {
    background-color: initial;
    color: rgb(131, 128, 128);
  }

  33% {
    background-color: #f1b708;
  }

  66% {
    background-color: #e6ae08;
  }

  100% {
    background-color: #d19e06;
    color: black;
  }
}

.viewOffer-btn {
  background: #401011;
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

/* .sub-container {
background-color: #d19e06;
} */

.border-container img {
  position: absolute;
  animation: prize-animation 4s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite alternate-reverse;
}

.border-container img[data-position="right"] {
  right: 0;
  height: 100vh;
  width: 25px;
  transform: rotate(180deg);
  /* min-height: 825px; */
  z-index: 1;
}

.border-container img[data-position="left"] {
  left: 0;
  height: 100vh;
  width: 25px;
  /* min-height: 825px; */
  z-index: 1;
}

.border-container img[data-position="bottom"] {
  bottom: 0;
  width: 100vw;
  height: 25px;
  transform: rotate(180deg);
  z-index: 1;
}

.border-container img[data-position="top"] {
  top: 0;
  z-index: 1;
  width: 100vw;
  height: 25px;
}

.main-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  animation: prize-animation 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite alternate-reverse;
}

.main-logo img[alt="main-logo"] {
  width: 340px;
}

.main-logo img[alt="hand_logo"] {
  width: 150px;
  margin-top: -13px;
  margin-left: 4px;
}

.main-logo img[alt="flower_logo"] {
  width: 300px;
  margin-top: -43px;
  margin-left: 4px;
  z-index: -1;
}

img[alt^="baloon"] {
  position: absolute;
}

img[alt="baloon_1"] {
  width: 190px;
  right: 15%;
  top: 10%;
  animation: baloon-animation-one 30s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite forwards;
}

img[alt="baloon_2"] {
  width: 190px;
  left: 45%;
  top: 30%;
  animation: baloon-animation-two 45s cubic-bezier(0.215, 0.61, 0.355, 1) 4s infinite forwards;
}

img[alt="baloon_3"] {
  width: 190px;
  right: 5%;
  top: 45%;
  animation: baloon-animation-three 45s cubic-bezier(0.6, -0.28, 0.735, 0.045) 10s infinite forwards;
}

img[alt="baloon_4"] {
  width: 190px;
  left: 10%;
  top: 52%;
  animation: baloon-animation-four 30s cubic-bezier(0.455, 0.03, 0.515, 0.955) 12s infinite forwards;
}

img[alt="baloon_5"] {
  width: 190px;
  left: 0%;
  bottom: 0%;
  animation: baloon-animation-five 35s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite forwards;
}

img[alt^="man"] {
  position: absolute;
  bottom: 0;
  width: 140px;
}

img[alt="man_right"] {
  right: 0;
}

img[alt="man_left"] {
  left: 0;
}

img[alt="flower_left"] {
  position: relative;
  left: 0;
  width: 140px;
  z-index: 1;
}

.watch {
  width: 89%;
  margin-left: -114px;
  scale: 1.2;
}

img[alt="flower_right"] {
  position: relative;
  width: 140px;
  z-index: 2;
}

input[type="date"]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}

input[type="date"]:focus::-webkit-datetime-edit {
  color: black !important;
}

.Main2 .MuiTextField-root > label {
  color: #2e457d;
}

.Main2 .MuiTextField-root > .MuiFormLabel-root.Mui-focused {
  color: #2e457d;
}

.getOffer-btn {
  border-radius: 12px;
  margin: auto;
}

.prize-container {
  position: relative;
  top: 0px;
}

.page_3_img_main {
  position: absolute;
  height: 485px;
  left: 0;
  margin-top: -96px;
  margin-left: -21px;
}

.prize-img {
  position: absolute;
  animation: prize-animation 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite alternate-reverse;
}

.prize-img[data-identifier="image"] {
  width: 158px;
  left: 28%;
  top: 17px;
}

.prize-img[data-identifier="cashback9"] {
  width: 174px;
  left: 27%;
  top: 79px;
}

.prize-img[data-identifier="cashback8"] {
  width: 174px;
  left: 27%;
  top: 62px;
}

.prize-img[data-identifier="cashback7"] {
  width: 187px;
  left: 24%;
  top: 46px;
}

.prize-img[data-identifier="cashback6"] {
  width: 164px;
  left: 27%;
  top: 46px;
}

.prize-img[data-identifier="cashback5"] {
  width: 164px;
  left: 27%;
  top: 46px;
}

.prize-img[data-identifier="cashback4"] {
  width: 149px;
  left: 30%;
  top: 38px;
}

.prize-img[data-identifier^="percent"] {
  width: 198px;
  left: 25%;
  top: 89px;
}

.prize-img[data-identifier="percent1"] {
  width: 198px;
  left: 24%;
  top: 82px;
}

img[alt^="Hand2"] {
  position: absolute;
  bottom: 8px;
  width: 372px;
  left: 11px;
}

img[alt="taj_background"] {
  position: absolute;
  width: 343px;
  left: 0px;
  bottom: 0px;
}

img[alt^="flowerleft"] {
  position: absolute;
  bottom: -2px;
  width: 120px;
  left: -7px;
  z-index: 3;
}

img[alt^="flowerright"] {
  position: absolute;
  bottom: 0px;
  width: 120px;
  right: -10px;
  z-index: 3;
}

@keyframes prize-animation {
  0% {
    filter: brightness(0.8);
    filter: contrast(0.6);
  }

  25% {
    filter: brightness(0.7);
    filter: contrast(1);
  }

  50% {
    filter: brightness(0.85);
    filter: contrast(1.1);
  }

  75% {
    filter: brightness(0.8);
    filter: contrast(2);
  }

  100% {
    filter: brightness(1);
    filter: contrast(1);
  }
}

.congo-img {
  width: 300px;
  position: relative;
  top: 240px;
  margin-right: 10px;
}

.page_1_submit_btn,
.page_3_btn {
  border: none;
  -webkit-transform: skew(-20deg);
  transform: skew(-20deg);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25098039215686274);
  background: #1b325e;
}

:where(.page_3_btn, .page_1_submit_btn) span {
  -webkit-transform: skew(20deg);
  transform: skew(20deg);
  display: inline-block;
}

.conatiner-parent {
  z-index: 3;
  width: 75%;
}

.Main2:has(form[data-pagenumber="first"]) {
  overflow: hidden;
}

.Main2:has(form[data-pagenumber="second"]) {
  overflow: hidden;
}

.Main2:has(form[data-pagenumber="third"]) {
  overflow: hidden;
}

.Main2:has(form[data-pagenumber="fourth"]) {
  overflow: hidden;
}

@media (max-width: 500px) {
  .main-logo img[alt="main-logo"] {
    width: 208px;
  }

  .main-logo img[alt="hand_logo"] {
    width: 185px;
    margin-top: -9px;
  }

  img[alt="baloon_1"] {
    width: 111px;
    right: 5%;
    top: 4%;
  }

  img[alt="baloon_2"] {
    width: 80px;
    left: 8%;
    top: 15%;
  }

  img[alt="baloon_3"] {
    width: 65px;
    right: 4%;
    top: 30%;
  }

  img[alt="baloon_4"] {
    width: 48px;
    left: 5%;
    top: 46%;
  }

  img[alt="baloon_5"] {
    width: 160px;
    left: -3%;
    bottom: 4%;
  }

  .input-group {
    margin: auto;
    margin-top: 0 !important;
  }

  .user-otp-text {
    font-size: 0.9rem;
  }

  .user-otp-sentBtn {
    font-size: 0.8rem;
  }

  .Main2 .otp-input {
    padding: 0.2rem 0.75rem;
    border-radius: 2px;
  }

  img[alt^="man"] {
    width: 92px;
  }

  /* form[data-pagenumber="first"] {
    min-height: 87ch;
  } */
  /* PAGE 2 */
  /* form[data-pagenumber="second"] {
    min-height: 75ch;
  } */

  .Main2:has(form[data-pagenumber="second"]) img[alt="taj_background"] {
    position: absolute;
    width: 400px;
    left: 20px;
    bottom: 18px;
  }

  .store-input {
    width: 85%;
  }

  .rating-text {
    font-size: 1rem;
    font-weight: 700;
    width: 85%;
    margin: auto;
  }

  .rating-icon {
    width: 50px;
    height: 50px;
  }

  /* PAGE 3 */
  form[data-pagenumber="third"] {
    min-height: 70ch;
  }

  .Main2:has(form[data-pagenumber="third"]) img[alt="taj_background"] {
    width: 403px;
    left: -2px;
    bottom: 19px;
  }

  /* PAGE 4 */
  form[data-pagenumber="fourth"] {
    min-height: 70ch;
  }
}

@media (max-width: 400px) {
  .conatiner-parent {
    width: 82%;
  }

  .Main2:has(form[data-pagenumber="second"]) img[alt="taj_background"] {
    width: 367px;
  }

  img[alt="flower_right"] {
    right: 67px !important;
  }
}

@media (max-width: 360px) {
  img[alt="flower_right"] {
    right: 85px !important;
  }
}

@media (min-width: 500px) {
  .Main2:has(form[data-pagenumber="second"]) img[alt="taj_background"] {
    width: 100vw;
  }

  .page_3_img_main {
    margin-left: 15%;
  }

  .Main2:has(form[data-pagenumber="third"]) #submit-otp-btn {
    top: 244px !important;
  }

  .Main2:has(form[data-pagenumber="third"]) img[alt="taj_background"] {
    width: 100vw;
  }

  /* .Main2:has(form[data-pagenumber="third"]) .container {
    height: 70ch;
  } */
}

@media (max-width: 370px) {
  .page_3_img_main {
    height: 424px;
  }
}

@media screen and (width: 320px) and (height: 480px) {
  .page_3_img_main {
    height: 391px;
  }

  .congo-img {
    width: 248px;
    position: relative;
    top: 193px;
    margin-right: 10px;
  }

  .page_3_btn {
    top: 230px !important;
  }
}

@keyframes baloon-animation-one {
  0% {
    top: 0%;
    right: 5%;
  }

  14% {
    top: 14%;
    right: 15%;
  }

  28% {
    top: 28%;
    right: 25%;
  }

  42% {
    top: 42%;
    right: 35%;
  }

  56% {
    top: 56%;
    right: 25%;
  }

  74% {
    top: 74%;
    right: 15%;
  }

  100% {
    top: 85%;
    right: 5%;
  }
}

@keyframes baloon-animation-two {
  0% {
    top: 15%;
    left: 8%;
  }

  14% {
    top: 30%;
    left: 24%;
  }

  28% {
    top: 45%;
    left: 38%;
  }

  42% {
    top: 65%;
    left: 48%;
  }

  56% {
    top: 45%;
    left: 38%;
  }

  74% {
    top: 30%;
    left: 24%;
  }

  100% {
    top: 15%;
    left: 8%;
  }
}

@keyframes baloon-animation-three {
  0% {
    top: 0%;
    right: 5%;
  }

  14% {
    top: 14%;
    right: 15%;
  }

  28% {
    top: 28%;
    right: 25%;
  }

  42% {
    top: 42%;
    right: 35%;
  }

  56% {
    top: 56%;
    right: 25%;
  }

  74% {
    top: 74%;
    right: 15%;
  }

  100% {
    top: 85%;
    right: 5%;
  }
}

@keyframes baloon-animation-four {
  0% {
    left: 5%;
    top: 46%;
  }

  14% {
    left: 25%;
    top: 66%;
  }

  28% {
    left: 45%;
    top: 86%;
  }

  42% {
    left: 65%;
    top: 6%;
  }

  56% {
    left: 85%;
    top: 36%;
  }

  74% {
    left: 45%;
    top: 66%;
  }

  100% {
    left: 5%;
    top: 46%;
  }
}

@keyframes baloon-animation-five {
  0% {
    bottom: 4%;
  }

  14% {
    bottom: 22%;
  }

  28% {
    bottom: 40%;
  }

  42% {
    bottom: 70%;
  }

  56% {
    bottom: 40%;
  }

  74% {
    bottom: 22%;
  }

  100% {
    bottom: 4%;
  }
}

.thankYouImageMainPage {
  width: 400px;
}

@media screen and (max-width: 420px) {
  .thankYouImageMainPage {
    width: 320px;
  }
}

.stage-img {
  width: 74%;
  margin-top: 161px;
}
