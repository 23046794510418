.side-by-side-offer-banner {
  object-fit: contain;
}

.carousel {
  overflow: hidden;
}

.card-display-row {
  display: flex;
  transition: transform 1s ease;
}

.card-display-row img {
  width: 100%; /* Adjust according to your image size */
  height: auto;
}

.side-by-side-offer-banner img:first-child {
  width: 49%;
}

.side-by-side-offer-banner img:last-child {
  width: 49.5%;
}

.bottom-section-offer img.find-store {
  z-index: 2;
}

.bottom-section-offer img.bottom-two-image {
  z-index: 1;
  margin-top: -2rem;
}

.last-section {
  background: linear-gradient(90deg, #ab2731 0%, #d16f31 53.33%, #cf6b31 63.49%, #c86032 74.43%, #bd4d32 85.74%, #af3132 97.23%, #ab2731 100.29%);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-block: 1.5rem;
}

.last-section > * {
  color: white;
  font-weight: bolder;
}

.web-icon,
.phone-icon {
  width: 20px;
  margin-right: 10px;
}

.visit-page {
  /* font-style: italic; */
}

.last-section > a:hover {
  color: rgba(255, 255, 255, 0.7);
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1);
  text-decoration: underline;
}

.offer-page-hover-banner,
.social-wrapper-bottom img {
  cursor: pointer;
}

.offer-page-hover-banner:hover,
.offer-page-hover-banner:focus-visible {
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  filter: brightness(1.1);
  scale: 1.01;
}

.social-wrapper-bottom img:hover,
.social-wrapper-bottom img:focus-visible {
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  filter: brightness(1.11);
  scale: 1.12;
}

.card-display-row iframe {
  height: 300px;
}

.social-wrapper-bottom {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.social-wrapper-bottom > * {
  flex-basis: 100%;
}

.social-wrapper-top > img {
  width: 280px;
}

@media screen and (max-width: 800px) {
  .card-display-row iframe {
    height: 180px;
    width: 180px;
  }

  .social-wrapper-top > img {
    width: 200px;
  }

  .social-wrapper-bottom img {
    width: 70px;
  }

  .card-display > img {
    width: 40%;
    height: 40%;
  }
}

@media screen and (max-width: 500px) {
  .side-by-side-offer-banner img:first-child {
    width: 47%;
  }

  .side-by-side-offer-banner img:last-child {
    width: 48.5%;
  }

  .card-display-row iframe {
    width: 38%;
  }

  .myRewardsTitle {
    margin-top: 1.2rem;
    margin-bottom: 1rem;
  }

  .visit-page,
  .web-icon + span,
  .phone-icon + span {
    font-size: 0.65rem;
  }

  .web-icon,
  .phone-icon {
    width: 11px;
    margin-right: 4px;
  }

  .last-section {
    padding-block: 1rem;
  }

  .visit-page {
    margin-top: 3px;
  }

  .social-wrapper-top > img {
    width: 120px;
  }

  .social-wrapper-bottom img {
    width: 50px;
  }

  .card-display-row iframe {
    height: 125px;
  }

  .card-display > img {
    width: 40%;
    height: 40%;
  }
}
