.prod {
  display: flex;
  /* border: 10px solid red; */
}

.products-component {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0rem;
  width: 100%;
  position: relative;
  /* height: 500px; */
}
.aside {
  /* background-color: #f2f2f2; */
  width: 20%;
  margin-left: 0.5rem;
}
.aside > img {
  width: 100%;
}
.products {
  width: 100%;
  padding: 0px 10px 0px 0px;
  padding-top: 4px;
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;
  margin-left: 10px;
}

.pre-btn,
.next-btn {
  border: none;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  transform: translateY(-50%);
  position: absolute;
  top: 40%;
  height: 92px;
  transition: background 0.5s ease-out;
  transition: transform 0.5s ease;
}
.product-card {
  scroll-snap-align: start;
}
.no-scrollonleft {
  transition: transform 0.5s ease; /* Add transition for smooth sliding effect */
  transform: translateX(5%);
}
.blurnxtBtn {
  opacity: 0.5;
}
.blurPrevBtn {
  opacity: 0.5;
}

.no-scrollonright {
  transition: transform 0.5s ease; /* Add transition for smooth sliding effect */
  transform: translateX(-5%);
}

@keyframes slideBackLeft {
  0% {
    transform: translateX(5%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideBackRight {
  0% {
    transform: translateX(-5%);
  }
  100% {
    transform: translateX(0);
  }
}

.pre-btn:hover,
.next-btn:hover {
  background: white !important;
  border: #008296 2px solid;
}

.pre-btn:hover svg,
.next-btn:hover svg {
  color: black;
}
.next-btn {
  right: 2px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.pre-btn {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
/* Additional styles for button hover effect */
.pre-btn:hover,
.next-btn:hover {
  background-color: white; /* Darken the background on hover */
  transform: translateY(-52%);
}

@media screen and (max-width: 1000px) {
  .products {
    overflow-x: scroll;
    width: 100%;
  }
  .products::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .products-component {
    margin: 0;
    /* white-space: nowrap;
    scroll-snap-type: x mandatory;
    transition: scroll-left 0.5s ease; */
  }
}
