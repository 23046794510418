.product_Information_Table {
  width: 100% !important;
  background-color: #ffffff;
  border-collapse: collapse;
  color: #000000;
  border-radius: 10px;
}

.product_Information_Table td {
  border-bottom-width: 1px;
  border-bottom-color: #707070;
  border-bottom-style: solid;
  padding: 0.5rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-left: 5%;
}

.product_Information_Table .product_Table_Row:first-child td {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.product_Information_Table .product_Table_Row:last-child td {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-style: none;
}

.product_Information_Table .product_Table_Row:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.product_Table_Key {
  background: #f8f9fa;
  color: #08090a;
  font-weight: 500 !important;
}

@media screen and (min-width: 768px) {
  .product_Information_Table td {
    padding: 0.75rem;
    padding-left: 5%;
  }
}

@media screen and (min-width: 1200px) {
  .product_Information_Table td {
    padding: 0.2rem 1.25rem;
    padding-left: 5%;
  }
}
