.cards_Container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  gap: 0.75rem;
  margin: 0.2rem 0;
}

.cart_Add_Items {
  padding: 0 0.75rem;
  background-color: #ffffff;
  margin: 0 0 1rem;
}

.add_Items_Wrapper {
  padding: 1rem 0.25rem;
  border-bottom: 0.063rem solid #ebedf0;
}

.add_Items_Wrapper p {
  letter-spacing: -0.01em;
  color: #08090a;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;
  margin: 0;
  max-width: 30ch;
}

.cart_More_Items {
  padding: 0.5rem 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cart_More_Items p {
  letter-spacing: -0.5px;
  /* color: #1B325E; */
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0;
}

.cart_More_Items img {
  max-width: 24px;
  max-height: 24px;
  width: 100%;
  height: 100%;
}

.cart_Save_Later {
  margin: 0.2rem 0;
}

.Productdetails {
  color: #707070;
  font-size: small;
  margin-top: 5px;
}

.save_Later_Header {
  padding: 0 0 0 1rem;
}

.save_Later_Header p {
  color: #6d747a;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
}

/* Cart Page Footer */
.cart_Footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  z-index: 300;
}

.cart_Footer_Left {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 50%;
}

.price-item {
  /* width: 75%; */
}

.footer_Price {
  letter-spacing: -0.01em;
  color: #08090a;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0;
}

.footer_Left_Text {
  letter-spacing: -0.5px;
  color: #1b325e;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0;
}

.cart_Footer_Right {
  width: 50%;
}

.cart_Subtotal_Section {
  display: none;
}

/* .delivery_Options {
  margin: 1rem 0 23.5rem;
} */

.option_Disabled {
  pointer-events: none;
  opacity: 0.6;
}

.cart_Coupon_Section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
  background: #ffffff;
  padding: 0.5rem 0;
}

.cart_Coupon_Input {
  background: #1b325e;
  padding: 0.5rem 0;
}

.address-detail-con {
  display: flex;
  width: 100%;
  padding: 0.5rem;
}

.add-itm1 {
  width: 35%;
}

.tHead {
  background-color: rgb(224, 224, 224);
}

.perProductPrice {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #241a6b;
}

.TotalPrice {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #241a6b;
}

#cart-address-detail {
  max-height: 200px; /* Set max height for the container */
  overflow-y: auto; /* Enable vertical scrolling when content exceeds max height */
  position: relative; /* Ensure proper positioning of sticky header */
}

#cart-address-detail > div {
  position: sticky; /* Make the div sticky */
  top: 0; /* Stick to the top of the container */
  background-color: white; /* Optional: Set background color */
  z-index: 1; /* Ensure header stays above content */
  padding: 10px; /* Add padding for better appearance */
}

#cart-address-detail > div > div:last-child > div {
  display: flex;
  align-items: center;
  padding: 10px;
}

#cart-address-detail::-webkit-scrollbar {
  display: none;
}

#cart-address-detail > div > div:last-child > div > span:first-child {
  margin-right: 10px;
}

#cart-address-detail > div > div:last-child > div > span:last-child {
  overflow: hidden;
  text-overflow: ellipsis; /* Show ellipsis for overflowing text */
}

.aside-total-price {
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
  border-radius: 10px;
  padding: 2rem 0rem;
}

.final-price-comp {
  font-size: 15px;
  width: 90%;
}

.text-gray {
  color: #707070;
}

#cart-address-detail {
  font-size: 20px;
  font-weight: 500;
}

.myTable th {
  background-color: #f2f2f2;
}

.cart-coupon {
  width: 100%;
  /* font-family: Quicksand; */
  /* padding: 1rem 0rem 0rem 6rem; */
}
.cart-coupon-child1 {
  width: 35%;
  padding: 1rem;
}

.coupon-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.coupon-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.head-size {
  font-size: 2rem;
  font-size: 40px;
  font-weight: 700;
  /* border: 1px solid red; */
}
.hr-coupon {
  width: 50%;
  /* height: 10%; */
  border: 1px solid #b2b2b2;
  /* margin-top: 2rem; */
}
.instruct-coupon {
  /* font-family: "Quicksand"; */
  font-weight: 700;
  font-size: 18px;
  color: #707070;
  /* width: 100px; */
  /* border: 1px solid red; */
  padding: 0.5rem;
}
.input-coupon {
  /* border: 1px solid black; */
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
}
.inpt {
  margin-top: 1rem;
}
.input-coupon input {
  width: 100%;
  padding: 0.5rem;
  outline: none;
  border: 1px solid rgb(203, 203, 203);
}
.coupon-btn {
  /* border: 1px solid green; */
  width: 30%;
  height: 100%;
  border: none;
}
.apply-coupon-btn {
  border-radius: 0 0 0 1rem;
  background-color: #1b325e;
  padding: 12px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  max-width: 200px;
  width: 100%;
  /* height: 10%; */
  outline: none;
  cursor: pointer;
  border: none;
  transform: skew(-20deg);
  box-shadow: 0px 4px 4px 0px #00000040;
  margin: 0 auto;
  font-weight: 700;
  /* border: 1px solid red; */
}
.cart-coupon-child2 {
  background-color: #f8f8f8;
  /* border: 1px solid black; */
  border-radius: 6px;
}
.coupon-details {
  /* font-family: Quicksand; */
  font-size: 15px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  padding: 20px 0px 0px 0px;
}
.coupon-typography {
  margin-left: 1.5rem;
}
#text2xl {
  font-size: 2rem;
  color: #1b325e;
}
.ml2px {
  margin-left: 10px;
}
.txt-clr-grey {
  color: #707070;
  margin-bottom: 6px;
}

.cart-detail-section2 {
  display: flex;
  /* border: 2px solid greenyellow; */
  background: #fff0bf;
  margin-bottom: 20px;
}

.sub-details {
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  padding: 0px 20px 0px 20px;
  font-size: 15px;
  font-weight: 700;
  justify-content: center;
  /* align-items: center; */
}
.sub-details img {
  width: 70%;
}

.cart-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 10px;
}

.delSave {
  display: flex;
  gap: 10px;
  padding-left: 5px;
  margin-top: 5px;
  justify-content: left;
  align-items: center;
}

.delSaveContent {
  font-style: normal;
  font-size: 12px;
  color: #3c96db;
  cursor: pointer;
}

.delSaveContent:hover {
  text-decoration: underline;
}

.myTable table {
  border-collapse: collapse;
  width: 100%;
}

.myTable td {
  text-align: left;
  padding: 8px;
  border: 1px solid #e0e0e0;
}

.myTable th {
  background-color: #f2f2f2;
}

.myTable td:first-child {
  border-left: 1px solid #ffffff;
}

.myTable td:last-child {
  border-right: 1px solid #ffffff;
}

.myTable tr:first-child td {
  border-top: 1px solid #ffffff;
}

.myTable tr:last-child td {
  border-bottom: 1px solid #ffffff;
}

.myTable td:not(:first-child):not(:last-child) {
  /* border-color: #e0e0e0; */
}

.myTable td:nth-child(2) {
  border-left: 1px solid #ffffff;
  width: 250px;
  border-right: none;
}
.myTable td:nth-child(3) {
  border-left: 1px solid #ffffff;
  width: 360px;
  border-right: none;
}
.myTable td:nth-child(5) {
  padding: 0 30px;
  width: 160px;
}

.cart-combo-offer {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #1b325e;
}

.cart-product-desc {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: small;
}
.prodTitle {
  /* font-size: 15px; */
  font-weight: 500;
}
#cart-heading {
  font-size: large;
}

@media screen and (max-width: 520px) {
  .address-detail-con {
    width: 100%;
    padding: 0.4rem;
  }
  .perProductPrice {
    font-size: 13px;
  }
  .TotalPrice {
    font-weight: 13px;
  }
  .aside-total-price {
    /* display: none; */
    width: 100%;
  }
  #cart-address-detail {
    font-size: 1.01rem;
    font-weight: 500;
    line-height: 1.3rem;
  }
  .hr-coupon {
    display: none;
  }
  .cart-coupon-child1 {
    width: 100%;
  }
  .cart-coupon-child2 {
    width: 100%;
  }
  .suggested-products {
    padding: 0.3rem 0.9rem 0.8rem 0.9rem;
  }

  .input-coupon {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (max-width: 991px) {
  .aside-total-price {
    width: 100%;
  }
  .cart-coupon-child1 {
    width: 100%;
  }
  .cart-coupon-child2 {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .delivery_Options {
    margin: 1rem 0 2.5rem;
  }

  .cart_Text {
    display: block;
    color: #08090a;
    padding: 0.875rem 1rem;
    letter-spacing: -0.01em;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
  }

  .cart_Subtotal_Section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 0.75rem;
    padding: 0.375rem 1.75rem 0.375rem 1rem;
  }

  .cart_Subtotal_Section > p,
  .cart_Subtotal_Section span {
    margin: 0;
    color: #08090a;
    letter-spacing: -0.01em;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5rem;
    width: 70%;
  }

  .cart_Subtotal_Section span {
    font-weight: 600;
  }

  .save_Later_Header {
    padding: 0.3rem 1rem;
  }

  .save_Later_Header p {
    letter-spacing: -0.01em;
    color: #08090a;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }

  .cart_Footer {
    display: none;
  }

  .cart_Footer_Right {
    max-width: 160px;
    width: 100%;
  }

  .delivery_Option_Submit_Button {
    margin: 0 0 15rem;
    text-decoration: none;
  }

  .delivery_Option_Submit_Button button,
  .home_Delivery_Submit button {
    max-width: 100%;
  }

  .cart_Coupon_Section {
    gap: 1.5rem;
    flex-direction: row;
    align-items: center;
    margin: 0 0 0.75rem;
    padding: 1rem 1.5rem;
  }

  .cart_Coupon_Input {
    background: #ffffff;
    padding: 0;
    width: 73%;
  }

  .cart_Coupon_Section .cart_Coupon_Button {
    width: 200px;
  }
}

@media screen and (min-width: 1024px) {
  .cart_Text {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .cart_Subtotal_Section {
    padding: 1rem 2rem 1rem 1.5rem;
  }
}

.input-field.coupon-applied {
  color: rgb(2, 173, 2);
  border: 2px solid;
}

.addressModalInput {
  outline: none;
  padding: 2px 10px;
}
.coupon-container {
  position: relative;
}

.coupon-tooltip {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  width: 250px;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ddd;
  z-index: 1;
}

.coupon-container:hover .coupon-tooltip {
  display: block;
}
